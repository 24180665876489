import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import Splide from '@splidejs/splide';

const initializeSliders = () => {


  const marqueeSlider = document.querySelector('.marquee-brands')
  ? new Splide('.splide.brands', {
    type: 'loop',
    drag: false,
    omitEnd: true,
    perPage: 9,
    gap: '8vw',
    autoScroll: {
      speed: 1,
      pauseOnHover: false,
      pauseOnFocus: false,
    },
  }).mount({AutoScroll})
  : null;
}

window.addEventListener('load', initializeSliders);